import { Clear } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import { EventHint, captureException } from "@sentry/react";
import { useLanguage, useSelector } from "hooks";
import { useState } from "react";
import { DotLoader } from "react-spinners";
import { toast } from "react-toastify";
import translations from "translations";
import {
  loadPowerlineFile,
  uploadFileToS3,
  waitForKmlProcessed,
  getGeoJson,
} from "./utils";
import { floor } from "lodash";

type Props = {
  disabled?: boolean;
  projectID: number | null;
  onLoad: () => void;
};

export default function UploadButton({ projectID, onLoad, disabled }: Props) {
  const [progress, setProgress] = useState(0);
  const [uploadFailed, setUploadFailed] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [files, setFiles] = useState<FileList | null>(null);
  const { language } = useLanguage();
  const gmap = useSelector((state) => state.map.gmap);

  const uploadDisabled = ![0, 100].includes(progress);
  const filesExists = files && files.length > 0;
  // @ts-ignore
  async function handleFileUpload(e) {
    setUploadFailed(false);
    setUploading(true);
    try {
      // @ts-ignore
      const urlLocations: string[] = await uploadFileToS3(files, projectID);
      // @ts-ignore
      const taskId = await waitForKmlProcessed(projectID, urlLocations, (p) =>
        setProgress(floor(100 * p))
      );
      if (!projectID || !gmap) return;
      const geojson = await getGeoJson(projectID, taskId);
      await loadPowerlineFile(gmap, geojson);
      setProgress(100);
      onLoad();
      setUploadFailed(false);
      setUploading(false);
    } catch (error) {
      setUploadFailed(true);
      setUploading(false);
      // Attempt to extract the files and add them as attachments
      const nFiles = files ? files.length : 0;
      const attachmentList: EventHint["attachments"] = [];
      try {
        for (let i = 0; i < nFiles; i++) {
          const file = files?.item(i);
          if (file) {
            attachmentList.push({
              data: await file.text(),
              filename: file.name,
              contentType: file.type,
            });
          }
        }
      } catch (err) {
        // Don't crash if we can't get the file data
        console.error("Failed to get file data for attachments", error);
      }

      // Capture the exception
      const exceptionHint: EventHint = {
        event_id: "kmluploader.uploadbutton.handlefileupload",
        originalException: error,
        data: {
          projectID,
        },
        attachments: attachmentList,
      };

      captureException(error, exceptionHint);

      const toastTranslation = // @ts-ignore
        translations.MissionLanding.OrderProgress.OrderMission.PowerlineUpload
          .Error.FailedUpload[language]; // @ts-ignore
      if (error?.response?.data) {
        // From the backend this'll be a string like SHAPE_FILE_MISSING_CRS.
        // @ts-ignore
        const errorData = error.response.data;
        const errorTranslation = // @ts-ignore
          translations.MissionLanding.OrderProgress.OrderMission.PowerlineUpload
            .Error[errorData][language];
        toast.error(`${toastTranslation}: ${errorTranslation}`);
      } else {
        toast.error(toastTranslation);
      }
    }
  }

  return (
    <Stack
      direction="row"
      alignItems="flex-start"
      justifyContent="space-between"
    >
      <Stack>
        <input
          type="file"
          accept=".kml, .zip"
          multiple
          disabled={uploadDisabled || disabled}
          onChange={(e) => {
            setFiles(e.target.files);
            setProgress(0);
          }}
        />
        <Typography sx={{ color: "text.secondary" }} variant="body2">
          {
            translations.MissionLanding.OrderProgress.OrderMission
              .PowerlineUpload.MultiFile[language]
          }
        </Typography>
      </Stack>
      <Stack direction="row" spacing={2} alignItems="center" mr={2}>
        <Button
          onClick={handleFileUpload}
          disabled={uploadDisabled || !filesExists || disabled}
          endIcon={
            uploadFailed ? (
              <Clear htmlColor="red" />
            ) : (
              <DotLoader loading={uploading} size={25} />
            )
          }
        >
          Upload
        </Button>

        {uploading && (
          <Box sx={{ position: "relative", display: "inline-flex" }}>
            <CircularProgress variant="determinate" value={progress} />
            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="caption"
                component="div"
                color="text.secondary"
              >{`${Math.round(progress)}%`}</Typography>
            </Box>
          </Box>
        )}
      </Stack>
    </Stack>
  );
}
