import { Flag, RemoveDone } from "@mui/icons-material";
import { Paper, Typography } from "@mui/material";
import RoleWrapper from "components/RoleHOC/wrapper";
import { useObjectTypeFilter } from "hooks";
import { reviewModes } from "constants/imageReview";
import ToolsContainer from "views/image/Review/ReviewTools/ToolsContainer";
import PrimaryAction from "views/image/Review/ReviewTools/PrimaryAction";
import IgnoreImage from "views/image/Review/ReviewTools/IgnoreImage/IgnoreImage";
import ToggleBBIcon from "views/image/Review/ReviewTools/SecondaryActions/ToggleBBIcon";
import CloseReviewToolIcon from "views/image/Review/ReviewTools/SecondaryActions/CloseReviewToolIcon";
import OpenInEditorIcon from "views/image/Review/ReviewTools/SecondaryActions/OpenInEditorIcon";

interface IProps {
  currentImage: number;
  currentIndex: number;
  navigateToNextImage: () => void;
  navigateToPrevImage: () => void;
  goToImageByIndex: (index: number) => void;
  imageIds: number[];
  approveAllAnnotations: () => void;
  flagImageForReannotation: () => void;
  loading: boolean;
  loadingAnnotations: boolean;
}
export default function FalsePositiveBbReview(props: IProps) {
  const {
    currentImage,
    currentIndex,
    navigateToNextImage,
    navigateToPrevImage,
    goToImageByIndex,
    imageIds,
    approveAllAnnotations,
    flagImageForReannotation,
    loading,
    loadingAnnotations,
  } = props;

  const { objectTypeFilter } = useObjectTypeFilter();

  if (!imageIds) {
    return <></>;
  }

  const primaryActions = (
    <>
      <PrimaryAction
        color="green"
        hotkey="q"
        tooltipText="Verify objects as false positives"
        onClick={approveAllAnnotations}
        icon={<RemoveDone sx={{ color: "#ffff" }} />}
        data-testid="falsePositiveBbReview.approveImage"
        disabled={loadingAnnotations}
      />
      <PrimaryAction
        color="red"
        hotkey="w"
        tooltipText="Flag image for reannotation"
        onClick={flagImageForReannotation}
        icon={<Flag sx={{ color: "#ffff" }} />}
        data-testid="falsePositiveBbReview.flagImage"
        disabled={loadingAnnotations}
      />
      <IgnoreImage
        imageId={currentImage}
        reviewModeId={reviewModes.FALSE_POSITIVE_BBS}
        objectTypeIds={objectTypeFilter}
        onIgnore={navigateToNextImage}
      />
    </>
  );

  const secondaryActions = (
    <>
      <ToggleBBIcon />
      <OpenInEditorIcon />
    </>
  );

  return (
    <RoleWrapper
      keyName="supervisorFalseReview"
      fallback={
        <Paper sx={{ p: 2 }}>
          <Typography>You do not have access to this feature</Typography>
        </Paper>
      }
    >
      <ToolsContainer
        loading={loading}
        currentIndex={currentIndex}
        navigateToNextImage={navigateToNextImage}
        navigateToPrevImage={navigateToPrevImage}
        goToImageByIndex={goToImageByIndex}
        imageIds={imageIds}
        primaryActions={primaryActions}
        secondaryActions={secondaryActions}
      />
      <CloseReviewToolIcon />
    </RoleWrapper>
  );
}
