import { Stack } from "@mui/material";
import StatusBar from "components/StatusBar";
import RoleWrapper from "components/RoleHOC/wrapper";
import translations from "translations";

// Import components to be rendered
import { CurrentAssignments, AssignmentOverview } from "./Assignments";
import FlaggedImageList from "./FlaggedImageList";
import ImageFeedbackList from "./ImageFeedbackList";
import ClientAlteredTypeList from "./ClientAlteredTypeList";
import WelcomeToSkyqraft from "./WelcomeToSkyqraft";
import LastUploads from "./LastUploads";
import OrderedMissions from "./OrderedMissions";
import ActiveMissions from "./ActiveMissions";
import PlannedMissions from "./PlannedMissions";
import DoneMissions from "./DoneMissions";
import { useLanguage } from "hooks";

export default function Landing() {
  const { language } = useLanguage();
  return (
    <Stack>
      <StatusBar title={translations.Landing[language]} />
      <Stack sx={{ overflowY: "auto" }}>
        <WelcomeToSkyqraft />
        {/* <NewDefects /> */}
        <Stack
          direction={{ xs: "column", sm: "column", md: "column", lg: "row" }}
          m={2}
          spacing={2}
        >
          <Stack spacing={2} flex={1}>
            <OrderedMissions />
            <PlannedMissions />
            <ActiveMissions />
            <DoneMissions />
          </Stack>
          <RoleWrapper
            multipleKeys={[
              "assignmentOverview",
              "imageFeedbackList",
              "clientAlteredTypes",
              "globalAssignments",
              "imageUpload",
            ]}
          >
            <Stack
              spacing={2}
              flex={1}
              width={{ xs: "100%", sm: "100%", md: "100%", lg: 100 }}
            >
              <RoleWrapper keyName="assignmentOverview">
                <AssignmentOverview />
                <FlaggedImageList />
              </RoleWrapper>
              <RoleWrapper keyName="imageFeedbackList">
                <ImageFeedbackList />
              </RoleWrapper>
              <RoleWrapper keyName="clientAlteredTypes">
                <ClientAlteredTypeList />
              </RoleWrapper>
              <RoleWrapper keyName="globalAssignments">
                <CurrentAssignments />
              </RoleWrapper>
              <RoleWrapper keyName="imageUpload">
                <LastUploads />
              </RoleWrapper>
            </Stack>
          </RoleWrapper>
        </Stack>
      </Stack>
    </Stack>
  );
}
